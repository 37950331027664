import {
  Add,
  Cancel,
  Delete,
  Edit,
  ExpandMore,
  MoreVert,
  Save,
  TextFields,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardActionArea,
  Typography,
  Card,
  Box,
  Stack,
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  DialogActions,
  Button,
  useTheme,
} from "@mui/material";

import CardMapsHome from "../cardMapsHome";
import Carousel from "../carousel";
import NewMap from "../newMap";
import AlertModal from "../../alertModal";
import { useState } from "react";
import api from "../../../services/api";
import useStyles from "./styles";

export default function GroupMaps({
  title = "",
  updateTitleGroup = () => {},
  groupsId,
  mapsGroup = [],
  setSnackbar,
  addNewMapInGroup = () => {},
  index,
  removeManyMapInGroup,
  removeGroup = () => {},
  maps = [],
  addMapInMaps = () => {},
  addMapsInGroup = () => {},
}) {
  const [openNovoMapaDialog, setOpenNovoMapaDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [updateTitleButton, setUpdateTitleButton] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [deleteArray, setDeleteArray] = useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const theme = useTheme();

  const styles = useStyles(theme);

  const DeleteModal = (props) => <AlertModal {...props} />;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const idsToDelete = new Set(deleteArray.map((item) => item.id));

  const filteredArray = mapsGroup.filter((item) => !idsToDelete.has(item?.id));

  const handleDelete = (id) => {
    const newArray = [...deleteArray];
    newArray.push({ id });
    setDeleteArray(newArray);
  };

  async function handleApiUpdate() {
    try {
      setUpdateTitleButton(true);
      const response = await api.put("/easyMaps/groupMaps/update", {
        id: groupsId,
        titulo: newTitle,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível atualizar o título",
          severity: "error",
        });
      } else {
        updateTitleGroup(newTitle, index);
        setOpenEditDialog(false);
      }
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setUpdateTitleButton(false);
      setAnchorEl(null);
    }
  }

  async function handleApiRemoveMapFromGroup() {
    try {
      setDisableButton(true);
      removeManyMapInGroup(index, deleteArray);
      const response = await api.post("/easyMaps/map/deleteMany", {
        mapsId: deleteArray.map((item) => item?.id),
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível excluir os mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          removeManyMapInGroup(index, deleteArray);
          setOpenDeleteDialog(false);
          setDeleteArray([]);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setDisableButton(false);
      setEditMode(false);
    }
  }

  async function handleApiDeleteGroup(params) {
    try {
      setDeleteButton(true);
      const response = await api.post("/easyMaps/groupMaps/delete", {
        id: groupsId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível excluir o grupo",
          severity: "error",
        });
      } else {
        removeGroup(index);
        setOpenDeleteModal(false);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setDeleteButton(false);
    }
  }

  return (
    <>
      <Accordion sx={styles.accordion} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.sumary}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
              {` ${title}  (${mapsGroup.length})`}
            </Typography>
            {!editMode ? (
              <IconButton
                id="options-button"
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVert />
              </IconButton>
            ) : (
              <Stack direction={"row"}>
                <Tooltip title={"Salvar"} arrow>
                  <IconButton
                    id="save-button"
                    onClick={(event) => {
                      event.stopPropagation();
                      if (
                        Array.isArray(deleteArray) &&
                        deleteArray.length > 0
                      ) {
                        setOpenDeleteDialog(true);
                      } else {
                        setEditMode(false);
                      }
                    }}
                  >
                    <Save color={"secondary"} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Cancelar"} arrow>
                  <IconButton
                    id="cancel-button"
                    onClick={(event) => {
                      setDeleteArray([]);
                      event.stopPropagation();
                      setEditMode(false);
                    }}
                  >
                    <Cancel color={"primary"} />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={styles.boxCard}>
            <Card sx={styles.card} variant="outlined">
              <CardActionArea
                onClick={() => setOpenNovoMapaDialog(true)}
                sx={styles.cardAction}
              >
                <Add sx={styles.icon} color="primary" />
                <Typography textAlign={"center"}>Adicionar mapa</Typography>
              </CardActionArea>
            </Card>
            <Carousel>
              {Array.isArray(mapsGroup) &&
                filteredArray.map((map, index) => {
                  return (
                    <CardMapsHome
                      key={map.id}
                      editMode={editMode}
                      mapName={map.titulo}
                      tipo={map.tipo}
                      id={map.id}
                      alertas={map.alertas}
                      hostsNames={[map.hosts]}
                      hostsCounts={map.hosts?.length || 0}
                      popsCounts={map.pops?.length || 0}
                      mapasCounts={map.mapas?.length || 0}
                      handleDelete={handleDelete}
                      mapas={map?.mapas}
                      pops={map?.pops}
                      mapToken={map?.mapToken}
                    />
                  );
                })}
            </Carousel>
          </Box>
          <NewMap
            setSnackbar={setSnackbar}
            openNovoMapaDialog={openNovoMapaDialog}
            setOpenNovoMapaDialog={setOpenNovoMapaDialog}
            groupsId={groupsId}
            groupPosition={index}
            addNewMapInGroup={addNewMapInGroup}
            maps={maps?.filter(
              (map) => !mapsGroup.map((item) => item.id).includes(map.id)
            )}
            addMapInMaps={addMapInMaps}
            addMapsInGroup={addMapsInGroup}
          />
        </AccordionDetails>
      </Accordion>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "options-button",
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              setOpenEditDialog(true);
            }}
          >
            <ListItemIcon>
              <TextFields fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Renomear</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setEditMode(true);
            }}
          >
            <ListItemIcon>
              <Edit fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Editar</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenDeleteModal(true);
            }}
          >
            <ListItemIcon>
              <Delete fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Excluir</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      <AlertModal
        openModal={openDeleteDialog}
        setOpenModal={setOpenDeleteDialog}
        confirmar={disableButton}
        handleButton={handleApiRemoveMapFromGroup}
        severity={"error"}
        singleButton={false}
      />
      <DeleteModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        confirmar={deleteButton}
        handleButton={handleApiDeleteGroup}
        severity={"error"}
        singleButton={false}
      />
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Renomear</DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Typography>Título</Typography>
          <TextField
            value={newTitle}
            onChange={(event) => setNewTitle(event.target.value)}
            autoFocus
            autoComplete="off"
            id="Titulo"
            placeholder="Titulo do grupo"
            type="text"
            fullWidth
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                document.getElementById("confirmButton").click();
              }
            }}
          />
          <DialogActions>
            <Button
              onClick={() => {
                setOpenEditDialog(false);
                setNewTitle(title);
              }}
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              id="confirmButton"
              sx={styles.confirmButton}
              disabled={updateTitleButton || newTitle.length === 0}
              onClick={handleApiUpdate}
            >
              Confirmar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
