import {
  Paper,
  Typography,
  Stack,
  Box,
  Link,
  useTheme,
  Tooltip,
  Button,
} from "@mui/material";

import IconTransform from "../../iconsElement";
import { AssignmentIndOutlined, AssignmentOutlined } from "@mui/icons-material";
export default function CardONU(props) {
  const { onu, search = () => {}, iconsElements = [] } = props;
  const theme = useTheme();

  const SvgMenu = ({ sx }) => {
    return (
      <svg
        style={sx}
        width="190"
        height="40"
        viewBox="0 0 163 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0.50119H127.163L163 22.5012H157.798L126.007 2.5477H0V0.50119Z"
          fill={theme.palette.color.iconsDisable}
        />{" "}
        {/* Primeiro path */}
        <path
          d="M146.799 21.8481L120 4.50119H124.937L157.377 24.9094L163 24.8261L160.904 29.5012H154.556L146.799 24.9094V21.8481Z"
          fill={theme.palette.primary.main}
        />{" "}
        {/* Segundo path */}
      </svg>
    );
  };

  const styles = {
    icon: {
      pointerEvents: "none",
      marginBottom: "-32px",
    },
    title: {
      fontSize: "1em",
      maxWidth: "150px",
      overflow: "hidden",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    subTitle: {
      mt: "-3px",
      fontSize: "0.8em",
      maxWidth: "165px",
      overflow: "hidden",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: theme.palette.text.disabled,
    },

    cardlinked: {
      width: "100%",
      backgroundColor: theme.palette.background.paperSelectedMaps,
      boxShadow: "0px 0px 5px  #0000001a",
      position: "relative",
      padding: "0px 15px",
      display: "flex",
      direction: "row",
      height: "85px",
      alignItems: "center",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  };

  return (
    <Tooltip title={onu.titulo} placement="left">
      <Button
        onClick={() => {
          search(onu.id);
        }}
        sx={{
          padding: "0px",
          width: "97%",
        }}
      >
        <Paper elevation={0} sx={[styles.cardlinked]}>
          <IconTransform
            img={iconsElements.find((a) => a.id == onu.img)?.img}
            size={"35px"}
            color={theme.palette.color.iconsDisable}
          />

          <Stack direction={"column"}>
            <Stack direction="row" alignItems={"center"} gap={0.5}>
              <AssignmentOutlined
                sx={{
                  fontSize: "13px",
                  color: "primary.main",
                }}
              />
              <Typography fontSize="12px">
                {onu?.data.contrato?.value}
              </Typography>
            </Stack>
            <SvgMenu selected={true} sx={styles.icon} />

            <>
              <Typography sx={styles.title}>{onu.titulo}</Typography>
              <Typography sx={styles.subTitle}>
                {onu?.data.usuario?.value}
              </Typography>
            </>
          </Stack>
        </Paper>
      </Button>
    </Tooltip>
  );
}
