import { alpha, createTheme } from "@mui/material/styles";
import { zabbixSeverty, appBarEasyMaps } from "./colors";

export const easyMapsTheme = (colorMode) =>
  createTheme({
    palette: {
      mode: !colorMode ? "light" : "dark",
      primary: {
        main: !colorMode ? "#ec4b60" : "#e83750",
      },
      secondary: {
        main: !colorMode ? "#5B2E87" : "#A77AD2",
      },

      drawer: {
        main: !colorMode ? "#5B2E87" : "#5B2E87",
        text: !colorMode ? "#E8E6E3" : "#ffffff",
        chat: !colorMode ? "#fff " : "#000000",
      },
      background: {
        hover: !colorMode ? "#f5f5f5ff" : "#252525",
        paper: !colorMode ? "#fff " : "#1E1E1E",
        default: !colorMode ? "#eeeeeeff" : "#141617", //EEF2F6
        slideBar: !colorMode ? "#eeeeeeff" : "#141617",
        paperLinkedMaps: !colorMode ? "#dcdcdc" : "#292929",
        paperSelectedMaps: !colorMode ? "#ffffff" : "#202020ff",
        paperNotification: !colorMode ? "#f1eeee" : "#282a2b",
        cardStatus: !colorMode ? "#EBEBEB" : "#242424",
        tab: !colorMode ? "#dcdcdc" : "#292929",
      },
      text: {
        primary: colorMode ? "#fff " : "#252525",
        invert: !colorMode ? "#fff " : "#252525",
        disabled: colorMode ? "#969696" : "#676767",
      },
      chip: {
        pending: !colorMode ? "#E83750" : "#df1130",
        inProgress: !colorMode ? "#ED6C02" : "#d46204",
        completed: !colorMode ? "#2E7D32" : "#387F4D",
        waitingReview: !colorMode ? "#0288D1" : "#077CB1",
        waitingReturn: !colorMode ? "#5B2E87" : "#502876",
        arquive: !colorMode ? "#777777" : "#424242",
      },
      color: {
        icons: colorMode ? "#fff " : "#313131",
        iconsDisable: colorMode ? "#575757ff" : "#b8b7b7",
        bgIcon: !colorMode ? "#d9d9d9 " : "#3b3b3b",
        text: colorMode ? "#fff " : "#313131",
        appBarBorder: colorMode ? "#404040" : "#c9c9c9",
        buttonConfirm: colorMode ? "#fff " : "#000000",
        menuItem: colorMode ? "#431D23" : "#F2DBDF",
        zabbixSeverty: zabbixSeverty(colorMode),
        appBarEasyMaps: appBarEasyMaps(colorMode),
        textEasyMapsInvert: !colorMode ? "#fff " : "#252525",
        textEasyMaps: colorMode ? "#fff " : "#252525",
        textDisabled: colorMode ? "#969696" : "#676767",
        status: {
          up: !colorMode ? "#387F4D" : "#387F4D",
          down: !colorMode ? "#EE1127" : "#EE1127",
        },
      },
      enlaces: {
        offline: { main: colorMode ? "#babdb7a1" : "#7c7b7bc2" },
        p0: { main: "#55b3a8" },
        p1: { main: "#56A64B" },
        p5: { main: "#37872D" },
        p15: { main: "#F2CC0C" },
        p20: { main: "#fcc71c" },
        p25: { main: "#E0B400" },
        p50: { main: "#FF780A" },
        p70: { main: "#FA6400" },
        p80: { main: "#da442a" },
        p90: { main: "#be0000" },
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: ({ theme }) => ({
            "& .MuiInputLabel-root": {
              fontSize: "1rem", // Tamanho do texto do label
            },
            "& .MuiInputBase-input": {
              fontSize: "1rem", // Tamanho do texto do placeholder
              display: "flex",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent",
              },
              boxShadow: "0px 0px 3px  #0000001a",
              backgroundColor: theme.palette.background.paperSelectedMaps,

              "&.Mui-focused": {
                backgroundColor: "none",
                "& fieldset": {
                  border: `1px solid  ${theme.palette.primary.main}`,
                },
                "&:hover": {
                  "& fieldset": {
                    border: `1px solid  ${theme.palette.primary.main}`,
                  },
                },
              },

              "&:hover": {
                backgroundColor: theme.palette.background.hover,
                "& fieldset": {
                  borderColor: "transparent",
                },
              },
              "&.Mui-disabled": {
                backgroundColor: alpha(
                  theme.palette.background.paperLinkedMaps,
                  0.2
                ),
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover": {
                  "& fieldset": {
                    borderColor: "none",
                  },
                },
              },
            },
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
            backgroundColor: !colorMode ? "#fff " : "#1E1E1E",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          containedPrimary: colorMode && { backgroundColor: "#e83750" },
        },
      },
      MuiChip: {
        styleOverrides: {
          containedPrimary: true && { backgroundColor: "#5B2E87" },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: !colorMode ? "#5B2E87" : "#121212",
            color: "#fff",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: !colorMode ? "#e83750" : "#e83750",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
            borderWidth: "2px",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            padding: "20px",
            borderRadius: "10px",
            display: "flex",
            gap: "8px",
            flexDirection: "column",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            "&.MuiTypography-root": {
              color: !colorMode ? "#ec4b60" : "#e83750",
              padding: 0,
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            display: "flex",
            gap: "8px",
            flexDirection: "column",
            padding: 1,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          color: colorMode ? "#fff " : "#313131",
        },
        variants: [
          {
            props: { variant: "small" },
            style: {
              fontSize: "0.75rem",
            },
          },
          {
            props: { variant: "default" },
            style: {
              fontSize: "0.875rem",
            },
          },
          {
            props: { variant: "textFieldTitle" },
            style: {
              fontSize: "0.875rem",
              fontWeight: 600,
            },
          },
          {
            props: { variant: "titleModals" },
            style: {
              fontSize: "1.5rem",
              color: !colorMode ? "#ec4b60" : "#e83750",
              fontWeight: 600,
            },
          },
        ],
      },
    },
    global: `
      .leaflet-layer,
      .leaflet-control-zoom-in,
      .leaflet-control-zoom-out,
      .leaflet-control-attribution {
        filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
      }
    `,
  });
