import React, { memo } from "react";
import { FixedSizeList as List } from "react-window";
import { Box, Stack } from "@mui/material";
import CardONU from "./index"; // Certifique-se de ajustar o caminho para o componente

const VirtualizedONU = memo(
  ({ lista, search, style, iconsElements }) => {
    // Define a altura de cada item (ajuste conforme necessário)

    // Renderiza cada item virtualizado
    const Row = ({ index, style }) => {
      const item = lista[index];
      return (
        <Box sx={style}>
          <CardONU onu={item} search={search} iconsElements={iconsElements} />
        </Box>
      );
    };

    return (
      <Stack direction="column" gap={1} sx={[style, { paddingRight: "0px" }]}>
        <List
          key={Math.random()}
          height={window.innerHeight} // Altura visível do container da lista
          itemCount={lista.length} // Total de itens na lista
          itemSize={95} // Altura de cada item
          width="100%" // Largura do container da lista
        >
          {Row}
        </List>
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.lista) == JSON.stringify(nextProps.lista);
  }
);

export default VirtualizedONU;
