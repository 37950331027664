export function nivelPotencia(potencia) {
  if (potencia == null) {
    return -1;
  } else if (potencia == -40) {
    return 3;
  } else if (potencia < -33) {
    return 2;
  } else if (potencia < -23) {
    return 1;
  } else {
    return 0;
  }
}
