import { Box, useTheme } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

const IconTransform = ({ img = "", size = "24px", color }) => {
  const theme = useTheme();

  if (img?.includes("svg")) {
    const svgString = atob(img?.split("base64,")[1]);
    const container = document.createElement("div");
    container.innerHTML = svgString;
    const svgElement = container.querySelector("svg");

    if (svgElement) {
      svgElement.style.fill = color ? color : theme.palette?.color?.icons;

      svgElement.style.width = size;
      svgElement.style.height = "auto";
      const updatedSvgString = container.innerHTML;

      // Convert the updated SVG back to a base64 URL
      img = "data:image/svg+xml;base64," + btoa(updatedSvgString);
    }
  }

  return <img src={img} width={size} height="auto" />;
};

export default IconTransform;
