import { icon } from "leaflet";

const useStyles = (theme) => ({
  accordion: {
    width: "100%",
    boxShadow: "0px 0px 5px  #0000001a",
    "&::before": {
      display: "none",
    },
  },
  sumary: { flexDirection: "row-reverse", gap: 1 },
  boxCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
    width: "100%",
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  card: {
    width: "120px",
    position: "relative",
    height: "180px",

    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  cardAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "180px",
    padding: "20px",
    gap: 1,
  },
  icon: {
    fontSize: "45px",
  },
  title: {
    fontSize: "18px",
    fontWeight: 500,
  },
  confirmButton: {
    color: theme?.palette?.color?.buttonConfirm || "primary.main",
  },
  dialogContent: { width: "400px" },
});

export default useStyles;
