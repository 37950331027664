import {
  Paper,
  Typography,
  Stack,
  Box,
  useTheme,
  Tooltip,
  Button,
} from "@mui/material";

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  SignalCellularConnectedNoInternet1Bar,
  SignalCellularNodataOutlined,
} from "@mui/icons-material";

export default function CardAlerts(props) {
  const { alert, setFocusNodeId } = props;
  const theme = useTheme();
  const [typeCard, setTypeCard] = useState({});

  useEffect(() => {
    function type() {
      return alert?.severity == 0
        ? {
            cor: theme.palette.enlaces.p90.main,
            icon: (
              <SignalCellularNodataOutlined
                sx={{ color: theme.palette.enlaces.p90.main }}
              />
            ),
            titulo: "Equipamento Offline",
          }
        : alert?.severity == 1
        ? {
            cor: theme.palette.enlaces.p50.main,
            icon: (
              <SignalCellularConnectedNoInternet1Bar
                sx={{ color: theme.palette.enlaces.p50.main }}
              />
            ),
            titulo: "Baixa Potência",
          }
        : null;
    }
    setTypeCard(type());
  }, [alert]);

  const styles = {
    title: {
      fontSize: "0.875em",
      maxWidth: "220px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textAlign: "left",
    },
    message: {
      mt: 0.5,
      fontSize: "0.75em",
      maxWidth: "200px",
      textAlign: "left",
      WebkitLineClamp: 2,
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      color: theme.palette.color.textDisabled,

      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    line: {
      backgroundColor: typeCard?.cor,
      height: "100%",
      width: "4px",
    },
    card: {
      backgroundColor: theme.palette.background.paperSelectedMaps,
      boxShadow: "0px 0px 5px  #0000001a",
      position: "relative",
      width: "100%",
      padding: "15px 10px",
      display: "flex",
      direction: "row",
      height: "90px",
      alignItems: "center",
      gap: 2,
      "&:hover": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  };

  return (
    <>
      <Tooltip title={alert?.name} placement="left">
        <Button
          onClick={() => setFocusNodeId(alert?.id)}
          sx={{
            padding: "0px",
            width: "97%",
          }}
        >
          <Paper elevation={0} sx={styles.card}>
            <Box sx={styles.line} />
            <Stack direction={"column"} alignItems="start">
              <Typography sx={styles.title}>
                {typeCard?.titulo?.toUpperCase()}
              </Typography>
              <Typography
                sx={{
                  ...styles.message,
                }}
              >
                {alert?.text}
              </Typography>
            </Stack>
            <Stack sx={{ position: "absolute", top: 15, right: 10 }}>
              {typeCard?.icon}
            </Stack>
          </Paper>
        </Button>
      </Tooltip>
    </>
  );
}
