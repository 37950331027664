import {
  ArrowForwardIos,
  BoltOutlined,
  LaunchOutlined,
  LinkOutlined,
  ListOutlined,
  Home,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import CardAlerts from "../sideBarEasyMaps/cardAlerts";
import VirtualizedAlerts from "../sideBarEasyMapsRp/cardAlerts/virtualizacao";
import ElementHeader from "../elementHeader";
import Detalhes from "./detalhes";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ButtonHide } from "../../../assets/easyMaps/buttonHide.svg";
import Conexao from "./conexoes";
import CardDetalhesRP from "./cardCTO";
import CardONU from "./cardONU";

const RightBarEasyMaps = ({
  element,
  elements,
  connections,
  iconsElement,
  setElement = () => {},
  fullScreem = false,
  link = "",
  setOpen = () => {},
  setFocusNodeId = () => {},
  openConnection,
  setOpenConnection = () => {},
  elementONUSelected,
  setElementONUSelected = () => {},
}) => {
  const { id, titulo, alerts, elementConfig, img, tipo, mapRef, mapRefId } =
    element || {};
  const [tipoMapa, setTipoMapa] = useState(0);
  const [enlacesConectados, setEnlacesConectados] = useState([]);
  const [valueTab, setValueTab] = useState(1);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(null);
  const [hosts, setHosts] = useState([]);
  const theme = useTheme();

  const handleChangeTab = (event, newValue) => {
    if (newValue == 1 && element.tipoBgpIxc != null) {
      setElement((ele) => ({ ...ele }));
      setElementONUSelected(null);
    }
    setValueTab(newValue);
  };
  //pegar as conexões desse host
  useEffect(() => {
    if (!element || !connections) return;
    let enlaces = connections.filter(
      ({ referenceId, destinationId }) =>
        id === destinationId || id === referenceId
    );
    setEnlacesConectados(enlaces);
  }, [connections, element]);

  useEffect(() => {
    if (element?.tipoBgpIxc != null) {
      if (elementONUSelected) {
        let index = element.elementsClients.findIndex(
          (en) => en.id == elementONUSelected.id
        );
        setExpanded(index);
        setValueTab(2);
      } else {
        setExpanded(null);
      }
    } else {
      if (openConnection) {
        let index = enlacesConectados.findIndex(
          (en) => en.id == openConnection
        );
        setExpanded(index);
        setValueTab(3);
      } else {
        setValueTab(1);
        setExpanded(null);
      }
    }
  }, [enlacesConectados]);

  // agrupar as informações por host
  useEffect(() => {
    if (!element || !element.id) {
      return;
    }

    let hostsProcess = [];
    setTipoMapa(element.mapRef?.tipo);
    let hostsElement = [];
    if (tipo == 0) {
      hostsElement = elementConfig?.config?.hosts ?? [];
    } else {
      let elementsMap = mapRef?.Element; //?.filter((ele) => ele.tipo == "0");
      hostsElement =
        elementsMap?.flatMap(({ elementConfig }) => {
          return elementConfig.config.hosts;
        }) ?? [];
    }

    hostsElement.map(({ host, hostid }) => {
      let snmp = element?.data?.snmp?.find(
        (snmp) => snmp.hostid == hostid
      )?.snmp_available;
      let latencia = element?.data?.latencia?.find(
        (item) => item.hostid == hostid
      )?.lastvalue;
      let ping = element?.data?.ping?.find(
        (item) => item.hostid == hostid
      )?.lastvalue;
      let perdaPing = element?.data?.perdaPing?.find(
        (item) => item.hostid == hostid
      )?.lastvalue;
      let itens = element?.data?.itens?.filter((item) => item.hostid == hostid);

      hostsProcess.push({
        name: host,
        hostid: hostid,
        perdaPing,
        ping,
        snmp,
        latencia,
        itens: itens,
      });
    });

    setHosts([...hostsProcess]);
  }, [element]);

  const styles = {
    line: {
      backgroundColor: "primary.main",
      height: "60px",
      width: "3px",
    },
    card: {
      overflow: "hidden",

      padding: "0px 10px",
      width: "100%",
      backgroundColor: theme.palette.background.paperLinkedMaps,
      "&:hover": {
        backgroundColor: theme.palette.background.paperSelectedMaps,
      },
    },
    paper: {
      borderRadius: "0px",
      height: "100vh",
      width: "340px",
      padding: "0px 10px",
      backgroundColor: theme.palette.background.slideBar,
    },
    drawer: {
      position: "absolute",
      clipPath: "none",
      marginTop: "65px",
      flexShrink: 0,
      overflow: "visible",
      backgroundColor: theme.palette.background.slideBar,
      "& .MuiDrawer-paper": {
        //zIndex: 1202,
        marginTop: fullScreem ? "" : "65px",
        overflow: "visible",
        backgroundColor: theme.palette.background.slideBar,
        borderLeft: "1px solid " + theme.palette.color.appBarBorder,
      },
    },
    stackContainerSvg: {
      position: "absolute",
      top: 0,
      left: "-28px",
      width: fullScreem ? "100vw" : "100%",
      height: fullScreem ? "100vh" : "calc(100vh - 70px)",
      zIndex: -1,
      background: "none",
      justifyContent: "center",
    },
    satckSvg: {
      alignItems: "center",
      justifyContent: "center",
      width: "fit-content",
    },
    iconButton: {
      position: "absolute",
      "&:hover": {
        borderColor: "none",
        boxShadow: "none",
        background: "none",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "none",
        borderColor: "none",
        background: "none",
      },
      "&:focus": {
        boxShadow: "none",
        backgroundColor: "none",
        borderColor: "none",
        background: "none",
      },
    },
    buttonHide: {
      height: "200px",
      width: "30px",
      fill: theme.palette.background.slideBar,
    },
    icon: {
      width: "20px",
      height: "20px",
      pl: "2px",
    },
    textMedium: { color: theme.palette.color.textDisabled },
    textLarge: { fontSize: "2em" },
    contentTab: {
      mt: "10px",
      paddingX: "5px",
      paddingY: "10px",
      gap: "10px",
      maxHeight: "75vh",
      overflow: "auto",
    },
    detailsCard: {
      backgroundColor: theme.palette.background.paperLinkedMaps,
      paddingX: "10px",
      paddingY: "15px",
      display: "flex",
      flexDirection: "column",
      gap: 2,
    },
    rowsDetails: { alignItems: "center", justifyContent: "space-between" },
    text: {
      fontSize: "0.875rem",
      color: theme.palette.color.text,
      //textTransform: "none",
    },
    data: {
      overflow: "auto",
      height: "100%",

      paddingBottom: "15px",
    },
  };
  function onClose() {
    setOpen(false);
    setExpanded(null);
    setOpenConnection(null);
  }
  useEffect(() => {
    if (element === null) {
      onClose();
    }
  }, [element]);
  const handleMouseDown = (event) => {
    let url = `${window.location.origin}${link}`;
    if (event.button === 1) {
      window.open(url, "_blank");
    }
  };
  return (
    <>
      <Drawer
        open={!!element?.id}
        //onClose={onClose}
        onAbort={onClose}
        anchor="right"
        sx={styles.drawer}
        variant="persistent"
      >
        <Stack sx={styles.stackContainerSvg}>
          <Stack sx={styles.satckSvg}>
            <ButtonHide style={styles.buttonHide} />
            <Tooltip title="Clique para fechar">
              <IconButton
                size="small"
                onClick={() => {
                  setElement(null);
                  //setConnections(null);
                }}
                sx={styles.iconButton}
              >
                <ArrowForwardIos sx={styles.icon} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>{" "}
        <Paper sx={styles.paper} elevation={0}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <ElementHeader
              mapa={mapRef}
              tipo={tipo}
              titulo={titulo}
              interfaces={
                Array.isArray(elementConfig?.config?.hosts) &&
                elementConfig?.config?.hosts?.length > 0
                  ? elementConfig?.config?.hosts[0]?.interfaces
                  : []
              }
              tags={
                Array.isArray(elementConfig?.config?.hosts) &&
                elementConfig?.config?.hosts?.length > 0
                  ? elementConfig?.config?.hosts[0]?.tags
                  : []
              }
              img={iconsElement?.find((icon) => icon.id == img)?.img}
            />
            {tipo != 0 && (
              <Tooltip title="ir para o mapa">
                <IconButton
                  disabled={!mapRefId}
                  sx={{ width: "40px", height: "40px", marginTop: "10px" }}
                  color="primary"
                  onMouseDown={handleMouseDown}
                  onClick={() => {
                    onClose();
                    setElement(null); // não remova
                    navigate(link);
                  }}
                >
                  <LaunchOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <Divider />
          {element?.tipoBgpIxc == null && (
            <>
              <Box>
                <Tabs
                  variant="fullWidth"
                  value={valueTab}
                  onChange={handleChangeTab}
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab
                    icon={<ListOutlined />}
                    label="Detalhes"
                    value={1}
                    sx={styles.text}
                  />
                  <Tab
                    icon={<BoltOutlined />}
                    label="Alertas"
                    value={2}
                    sx={styles.text}
                  />
                  <Tab
                    icon={<LinkOutlined />}
                    label="Conexões"
                    value={3}
                    sx={styles.text}
                  />
                </Tabs>
                <Divider />
              </Box>

              {valueTab === 1 && (
                <Stack sx={styles.contentTab}>
                  {hosts?.map((host, index) => {
                    return (
                      <Detalhes
                        host={host}
                        key={index + "hostslist"}
                        unique={hosts.length == 1}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        index={index}
                      />
                    );
                  })}
                </Stack>
              )}
              {valueTab === 2 && (
                <Stack sx={styles.contentTab}>
                  {alerts?.map((alerta, index) => (
                    <CardAlerts
                      key={"aletas" + index}
                      alert={alerta}
                      setFocusNodeId={() => setFocusNodeId(element.id)}
                    />
                  ))}
                </Stack>
              )}
              {valueTab === 3 && (
                <Stack sx={styles.contentTab}>
                  {enlacesConectados?.map((conn, index) => (
                    <Conexao
                      key={"conn" + index}
                      conexao={conn}
                      element={element}
                      elements={elements}
                      unique={enlacesConectados.length == 1}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      index={index}
                    />
                  ))}
                </Stack>
              )}
            </>
          )}
          {element?.tipoBgpIxc != null && (
            <>
              <Box>
                <Tabs
                  variant="fullWidth"
                  value={valueTab}
                  onChange={handleChangeTab}
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab
                    icon={<ListOutlined />}
                    label="Detalhes"
                    value={1}
                    sx={styles.text}
                  />
                  <Tab
                    icon={<BoltOutlined />}
                    label="Alertas"
                    value={3}
                    sx={styles.text}
                  />

                  <Tab
                    icon={<Home />}
                    label="ONUs"
                    value={2}
                    sx={styles.text}
                  />
                </Tabs>
                <Divider />
              </Box>

              {valueTab === 1 && (
                <Stack sx={styles.contentTab}>
                  <CardDetalhesRP
                    element={element}
                    key={"cardOlt" + element.id}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    index={0}
                  />
                </Stack>
              )}

              {valueTab === 3 && (
                <Stack sx={styles.contentTab}>
                  <VirtualizedAlerts
                    alertasTotais={[
                      ...element?.elementsClients?.flatMap((a) => a.alert),
                      ...element?.alerts,
                    ]}
                    search={setFocusNodeId}
                    style={styles.data}
                  />
                </Stack>
              )}

              {valueTab === 2 && (
                <Stack sx={styles.contentTab}>
                  {element.elementsClients?.map((onu, index) => (
                    <CardONU
                      key={"conn" + index}
                      onu={onu}
                      unique={enlacesConectados.length == 1}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      index={index}
                      setElementONUSelected={setElementONUSelected}
                      setFocusNodeId={setFocusNodeId}
                    />
                  ))}
                </Stack>
              )}
            </>
          )}
        </Paper>
      </Drawer>
    </>
  );
};
// const CardMapsZoomTop = ({ connections }) => {
//   return;
//   // const { fitView } = useReactFlow();
//   // const styles = { paddingX: "5px", paddingY: "10px", gap: "10px" };
//   // const handleZoomNode = (id) => {
//   //   if (id) {
//   //     fitView({ nodes: [{ id }], duration: 1000, maxZoom: 3 });
//   //   }
//   // };
//   // return (
//   //   <Stack sx={styles}>
//   //     {Array.isArray(connections) &&
//   //       connections.length > 0 &&
//   //       connections?.map(({ host, titulo, alerts, tipo, id }, index) => (
//   //         <CardMaps
//   //           key={index}
//   //           host={host}
//   //           link={"#"}
//   //           title={titulo}
//   //           alertas={alerts.length}
//   //           tipo={tipo}
//   //           id={id}
//   //           onClick={() => {
//   //             handleZoomNode(id);
//   //           }}
//   //         />
//   //       ))}
//   //   </Stack>
//   // );
// };

// const CardMapsZoomGeo = ({ connections }) => {
//   const styles = { paddingX: "5px", paddingY: "10px", gap: "10px" };
//   const handleZoomNode = (id) => {
//     if (id) {
//       //funcao para dar zoom no node
//     }
//   };
//   return (
//     <Stack sx={styles}>
//       {connections?.map(({ host, titulo, alerts, tipo, id }, index) => (
//         <CardMaps
//           key={index}
//           host={host}
//           link={"#"}
//           title={titulo}
//           alertas={alerts.length}
//           tipo={tipo}
//           id={id}
//           onClick={() => {
//             handleZoomNode(id);
//           }}
//         />
//       ))}
//     </Stack>
//   );
// };

// RightBarEasyMaps.CardMapsZoomGeo = CardMapsZoomGeo;
// RightBarEasyMaps.CardMapsZoomTop = CardMapsZoomTop;
export default RightBarEasyMaps;
