import { useEffect, useState, useRef } from "react";
import SideBarMaps from "../../../componentes/easyMaps/sideBarEasyMapsRp";
import api from "../../../services/api";
import apiPublic from "../../../services/apiPublic";
import {
  Stack,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import "../../../componentes/leaflet-migrations";
import "../../../componentes/leaflet-fullscreen/Leaflet.fullscreen";
import LeafletMapa from "./leaflatMapa";
import { useNavigate } from "react-router-dom";
import LoadingUpdate from "../../../componentes/easyMaps/loadingUpdate";

export default function MapaGeolocalizado({ socketCliente, isPublic = false }) {
  const tipo = "easymon";
  const mapRef = useRef();
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState(null);
  const [produtos, setProdutos] = useState(false);
  const [map, setMap] = useState(null);
  const [elements, setElements] = useState([]);
  const [elementSelected, setElementSelected] = useState(null);
  const [elementONUSelected, setElementONUSelected] = useState(null);
  const [connectionSelected, setConnectionSelected] = useState(null);
  const [connections, setConnections] = useState([]);
  const [fullScreem, setFullScreem] = useState(false);
  const [mapas, setMapas] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [iconsElements, setIconsElements] = useState([]);
  const [loadingMap, setLoadingMap] = useState(true);
  const navigate = useNavigate();
  const [checkChanges, setCheckChanges] = useState(false);
  const [sinc, setSinc] = useState(false);
  const [easyMapsConfig, setEasyMapsConfig] = useState(null);
  useEffect(() => {
    let config = JSON.parse(localStorage.getItem("easyMapsConfig")) || null;
    if (config) setEasyMapsConfig(config);
  }, []);

  useEffect(() => {
    if (!easyMapsConfig) return;
    localStorage.setItem("easyMapsConfig", JSON.stringify(easyMapsConfig));
  }, [easyMapsConfig]);
  const { groupMapsId, id } = useParams();
  const styles = {
    fullScreem: {
      position: "fixed",
      zIndex: 3000,
      width: "100vw",
      height: "100vh",
      left: 0,
      top: 0,
    },
    loadingMap: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.mode != "dark" ? "#e0e0e093" : "#00000093",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: 4,
    },
  };

  useEffect(() => {
    if (elementSelected == null || editMode == true) return;

    setElementSelected({
      ...elements.find((ele) => ele.id == elementSelected.id),
    });
  }, [elements]);
  useEffect(() => {
    if (connectionSelected == null || checkChanges) return;

    setConnectionSelected({
      ...connections.find((conn) => conn.id == connectionSelected.id),
    });
  }, [connections]);

  useEffect(() => {
    setLoadingMap(true);
    inicializeMap();
    const interval = window.setInterval(() => {
      handleApiGetElements();
    }, 60000 * 5);

    return () => window.clearInterval(interval);
  }, [id]);
  async function inicializeMap() {
    await Promise.all([
      handleApiGetMap(id),
      handleApiGetElements(id),
      getIconDB(),
    ]);
    setLoadingMap(false);
  }
  async function handleApiGetMap(id) {
    try {
      let response;
      if (isPublic) {
        response = await apiPublic.post("/easyMaps/public/map/findMapById", {
          mapId: id,
        });
      } else {
        response = await api.post("/easyMaps/map/findMapById", {
          mapId: id,
        });
      }

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar o mapa",
          severity: "error",
        });
      } else {
        // if (response.data.tipo != 3) navigate("/easyMaps");
        // else setMap(response.data);
        setMap(response.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status == 404) navigate("/easyMaps/notFound");
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiGetElements(id) {
    setSinc(true);
    try {
      //setLoadingProdutos(true);
      let response;
      if (isPublic) {
        response = await apiPublic.post("/easyMaps/public/rp/findMany", {
          mapId: id,
        });
      } else {
        response = await api.post("/easyMaps/rp/findMany", {
          mapId: id,
        });
      }

      if (response?.data?.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          setElements(response.data.responseAlerts);
          setConnections(response.data.connections);
        }
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: `Error: ${
          error?.response?.data?.error ||
          "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setSinc(false);
    }
  }

  async function getIconDB() {
    try {
      let response;
      if (isPublic) {
        response = await apiPublic.get("/easyMaps/public/img/findMany");
      } else {
        response = await api.get("/easyMaps/img/findMany");
      }

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar os icons",
          severity: "error",
        });
      } else {
        setIconsElements(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  return (
    <>
      <Stack direction="row">
        <SideBarMaps
          map={map}
          iconsElements={iconsElements}
          mapRef={mapRef}
          elements={elements}
        />
        <Stack
          sx={
            !fullScreem
              ? {
                  position: "relative",
                }
              : styles.fullScreem
          }
          width="100%"
        >
          {loadingMap && (
            <Box sx={styles.loadingMap}>
              <CircularProgress size="50px" disableShrink />
              <Typography
                fontSize="14px"
                color={theme.palette.color.textEasyMaps}
              >
                Carregando dados do mapa...
              </Typography>
            </Box>
          )}
          <LoadingUpdate
            visible={sinc && !loadingMap}
            elementSelected={!!elementSelected}
          />

          <LeafletMapa
            setEasyMapsConfig={setEasyMapsConfig}
            easyMapsConfig={easyMapsConfig}
            setFullScreem={setFullScreem}
            fullScreem={fullScreem}
            socketCliente={socketCliente}
            ref={mapRef}
            elements={elements}
            connections={JSON.parse(
              JSON.stringify(connections ? connections : [])
            )}
            setElementSelected={setElementSelected}
            setElementONUSelected={setElementONUSelected}
            connectionSelected={connectionSelected}
            setConnectionSelected={setConnectionSelected}
            elementSelected={elementSelected}
            elementONUSelected={elementONUSelected}
            iconsElements={iconsElements}
            setIconsElement={setIconsElements}
            mapId={id}
            title={map?.titulo}
            element={editMode ? elementSelected : null}
            setElements={setElements}
            setConnections={setConnections}
            produtos={produtos}
            api={api}
            setSnackbar={setSnackbar}
            mapas={mapas}
            setMapas={setMapas}
            editMode={editMode}
            setEditMode={setEditMode}
            checkChanges={checkChanges}
            setCheckChanges={setCheckChanges}
          />
        </Stack>
      </Stack>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </>
  );
}
