import {
  Divider,
  Box,
  Paper,
  Stack,
  Typography,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import StatusCTO from "../cardCTO/status";

export default function CardDetalhesRP({ element }, key) {
  const theme = useTheme();
  const [mediaEntrada, setMediaEntrada] = useState("-");
  const [mediaSaida, setMediaSaida] = useState("-");

  useEffect(() => {
    setMediaEntrada(element.data.potencia.rx?.toFixed(1));
    setMediaSaida(element.data.potencia.tx?.toFixed(1));
  }, [element]);

  const styles = {
    line: {
      position: "absolute",
      height: "80%", //typeElement == "Host" ? "150px" : "230px",
      minWidth: "3px",
      ml: "-5px",
    },
    textAlerta: {
      fontSize: "0.85rem",
      color: theme.palette.color.textDisabled,
    },
    textMedium: { color: theme.palette.color.textDisabled },
    textLarge: { fontSize: "2em" },
    contentTab: { gap: "10px" },
    detailsCard: {
      backgroundColor: theme.palette.background.paperLinkedMaps,

      paddingX: "10px",
      paddingY: "15px",
      display: "flex",
      flexDirection: "column",
      gap: 2,
    },
    rowsDetails: { alignItems: "center", justifyContent: "space-between" },
    textHeaderAco: {
      fontSize: "0.85rem",

      color: theme.palette.color.text,
    },
  };

  return (
    <Accordion
      key={key}
      disableGutters
      expanded={true}
      elevation={0}
      //onChange={() => handleChange(index)}
      sx={{
        padding: "5px",
        borderRadius: "5px",
        backgroundColor: theme.palette.background.paperSelectedMaps,
        boxShadow: "0px 0px 3px  #0000001a",
        border: "none",
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={null}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          padding: "0px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          width="100%"
          ml={1}
        >
          <Tooltip title={element.titulo}>
            <Typography
              sx={{
                fontSize: "0.9em",
                maxWidth: "250px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                ml: "5px",
              }}
            >
              {element.titulo}
            </Typography>
          </Tooltip>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "5px" }}>
        <Stack sx={styles.contentTab}>
          <Paper sx={styles.detailsCard} elevation={0}>
            <Stack direction={"column"} gap={2}>
              <Typography textAlign={"center"} sx={styles.textAlerta}>
                Potência média da CTO
              </Typography>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Stack alignItems={"center"}>
                  <Stack direction={"row"} sx={{ alignItems: "flex-end" }}>
                    <Typography sx={styles.textLarge}>
                      {mediaEntrada}
                    </Typography>
                    <Typography color="primary" mb={"6px"}>
                      dBm
                    </Typography>
                  </Stack>
                  <Typography sx={styles.textMedium}>Entrada</Typography>
                </Stack>
                <Stack alignItems={"center"}>
                  <Stack direction={"row"} sx={{ alignItems: "flex-end" }}>
                    <Typography sx={styles.textLarge}> {mediaSaida}</Typography>
                    <Typography color="primary" mb={"6px"}>
                      dBm
                    </Typography>
                  </Stack>{" "}
                  <Typography sx={styles.textMedium}> Saida</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Divider variant="middle" />
            <StatusCTO potenciaRx={mediaEntrada} />
          </Paper>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
