import React, { memo } from "react";
import { FixedSizeList as List } from "react-window";
import { Box, Stack } from "@mui/material";
import CardAlerts from "./index"; // Certifique-se de ajustar o caminho para o componente

const VirtualizedAlerts = memo(
  ({ alertasTotais, search, style }) => {
    // Define a altura de cada item (ajuste conforme necessário)

    // Renderiza cada item virtualizado
    const Row = ({ index, style }) => {
      const alerta = alertasTotais[index];
      return (
        <Box sx={style}>
          <CardAlerts
            key={"alertas" + index}
            alert={alerta}
            setFocusNodeId={search}
          />
        </Box>
      );
    };

    return (
      <Stack direction="column" gap={1} sx={[style, { paddingRight: "0px" }]}>
        <List
          key={Math.random()}
          height={window.innerHeight} // Altura visível do container da lista
          itemCount={alertasTotais.length} // Total de itens na lista
          itemSize={100} // Altura de cada item
          width="100%" // Largura do container da lista
        >
          {Row}
        </List>
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.alertasTotais) ==
      JSON.stringify(nextProps.alertasTotais)
    );
  }
);

export default VirtualizedAlerts;
