import zIndex from "@mui/material/styles/zIndex";
import { icon } from "leaflet";

const useStyles = (theme, editMode = false) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: "360px",
    maxWidth: "620px",
    width: "100%",
    position: "relative",
  },

  deleteCardAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "180px",
    position: "absolute",
    zIndex: 1,
    right: 0,
    top: 0,
  },
  cardAction: {
    padding: "20px",
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    alignItems: "flex-start",
    //justifyContent: "flex-start",
    width: "100%",
    height: "180px",
    opacity: editMode ? 0.15 : 1,
  },
  alerts: {
    alignItems: "center",
    fontSize: 14,
    width: "100%",
  },
  alertsIcons: {
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  icons: {
    color: "white",
    fontSize: "20px",
  },
  iconsCircle: {
    height: "fit-content",
    width: "fit-content",
    padding: "3px 8px",
    gap: "5px",
    borderRadius: "13px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteIcon: { fontSize: "60px" },
  title: {
    fontSize: 16,
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 700,
  },
  subtitleAlert: {
    fontSize: 13,
    fontWeight: 700,
    color: "white",
  },
  cardType: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 20,
    top: 0,
    backgroundColor: "primary.main",
    paddingX: "15px",
    paddingY: "5px",
  },
  options: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  hostsNames: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  typeText: { fontSize: "12px", fontWeight: 500, color: "white" },
  dialogContent: { width: "400px" },
  confirmButton: {
    color: theme?.palette?.color?.buttonConfirm || "primary.main",
  },
});

export default useStyles;
