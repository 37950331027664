import {
  alpha,
  Box,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { nivelPotencia } from "../../../utils/easyMaps/niveisDePotencia";

export const DescriptionBarRp = ({ elementSelected = false }, key) => {
  let theme = useTheme();
  const cores = [
    { cor: theme.palette.enlaces.offline.main, label: "Sem Dados" },
    { cor: theme.palette.enlaces.p5.main, label: "-16dBm" },
    { cor: theme.palette.enlaces.p20.main, label: "-23dBm" },
    { cor: theme.palette.enlaces.p50.main, label: "-33dBm" },
    { cor: theme.palette.enlaces.p90.main, label: "-40dBm" },
  ];
  const styles = {
    bar: {
      position: "absolute",
      backgroundColor: alpha(theme.palette.background.default, 0.7),
      p: 0.5,
      left: elementSelected ? 0 : null,
      right: elementSelected ? null : 0,
      bottom: 0,

      zIndex: 10,
      flexDirection: "row",
    },
    box: {
      minHeight: "10px",
      minWidth: "75px",
      ml: "-1px",
    },
  };
  return (
    <Stack sx={styles.bar} key={key}>
      {cores.map((cor, index) => (
        <Stack
          key={"barColor" + index}
          direction="row"
          sx={{ justifyContent: "center", height: "25px" }}
        >
          <Divider orientation="vertical" />
          <Stack gap={0.4}>
            <Box
              sx={[
                styles.box,
                {
                  backgroundColor: cor.cor,
                },
              ]}
            />
            <Typography
              sx={{
                fontSize: "10px",
                ml: "2px",
                fontWeight: 500,
                color: theme.palette.text,
                opacity: 0.8,
              }}
            >
              {" "}
              {cor.label}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export const colorPower = (power, theme) => {
  let pot = nivelPotencia(power);
  const cores = [
    { cor: theme.palette.enlaces.offline.main, label: "Offline" },
    { cor: theme.palette.enlaces.p5.main, label: "-16dBm" },
    { cor: theme.palette.enlaces.p20.main, label: "-23dBm" },
    { cor: theme.palette.enlaces.p50.main, label: "-33dBm" },
    { cor: theme.palette.enlaces.p90.main, label: "-40dBm" },
  ];
  if (power == null) return cores[0].cor;
  return cores[pot + 1].cor;
};
