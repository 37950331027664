import {
  CheckCircleOutline,
  ErrorOutline,
  ErrorOutlineOutlined,
  SignalCellular3BarOutlined,
  SignalCellular4BarOutlined,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellularNodataOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { nivelPotencia } from "../../../../utils/easyMaps/niveisDePotencia";
import { colorPower } from "../../descriptionBarRp";

export default function StatusCTO({ potenciaRx }) {
  const theme = useTheme();
  const [qualidade, setQualidade] = useState(1);
  const [color, setColor] = useState(null);
  const styles = {
    info: {
      color: theme.palette.color.textDisabled,
      width: "100%",
    },
    statusCard: {
      backgroundColor: "transparent",
      paddingX: "10px",
      paddingY: "0px",
      display: "flex",
      gap: 2,
      height: "60px",
    },
    line: { backgroundColor: "#FFFFFF", width: "3px", height: "100%" },
  };
  useEffect(() => {
    let pot = nivelPotencia(potenciaRx);
    setQualidade(pot);
    setColor(colorPower(potenciaRx, theme));
  }, [potenciaRx]);
  return (
    <Paper sx={styles.statusCard} elevation={0} variant="miniCards">
      <Stack direction={"column"} width={"100%"} gap={0.5}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="default">{"Status da potência"}</Typography>
          {qualidade == 0 && (
            <SignalCellular4BarOutlined
              sx={{ color: color, fontSize: "22px" }}
            />
          )}
          {qualidade == 1 && (
            <SignalCellular3BarOutlined
              sx={{
                color: color,
                fontSize: "22px",
              }}
            />
          )}
          {qualidade == 2 && (
            <SignalCellularConnectedNoInternet0Bar
              sx={{
                color: color,
                fontSize: "22px",
              }}
            />
          )}
          {qualidade == 3 && (
            <SignalCellularNodataOutlined
              sx={{
                color: color,
                fontSize: "22px",
              }}
            />
          )}
        </Stack>

        {qualidade == 0 && (
          <Typography sx={styles.info} variant="small">
            {"A recepção média do sinal está em condições ideais"}
          </Typography>
        )}
        {qualidade == 1 && (
          <Typography sx={styles.info} variant="small">
            {
              "A recepção média do sinal está razoável, apresentando qualidade moderada"
            }
          </Typography>
        )}
        {qualidade == 2 && (
          <Typography sx={styles.info} variant="small">
            {"A média do sinal recebido está ruim e instável"}
          </Typography>
        )}
        {qualidade == 3 && (
          <Typography sx={styles.info} variant="small">
            {"Todas as ONU estão offline, sem conexão ativa"}
          </Typography>
        )}
      </Stack>
    </Paper>
  );
}
