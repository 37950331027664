import { useEffect, useState, useRef } from "react";
import SliderBarMaps from "../../../componentes/easyMaps/sideBarEasyMaps";
import api from "../../../services/api";
import {
  Stack,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import "../../../componentes/leaflet-migrations";
import "../../../componentes/leaflet-fullscreen/Leaflet.fullscreen";
import LeafletMapa from "./leaflatMapa";
import { useNavigate } from "react-router-dom";

export default function MapaGeolocalizado({ socketCliente }) {
  const tipo = "easymon";
  const mapRef = useRef();
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState(null);
  const [produtos, setProdutos] = useState(false);
  const [map, setMap] = useState(null);
  const [elements, setElements] = useState([]);
  const [elementSelected, setElementSelected] = useState(null);
  const [connectionSelected, setConnectionSelected] = useState(null);
  const [connections, setConnections] = useState([]);
  const [fullScreem, setFullScreem] = useState(false);
  const [mapas, setMapas] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [iconsElements, setIconsElements] = useState([]);
  const [loadingMap, setLoadingMap] = useState(true);
  const navigate = useNavigate();
  const [checkChanges, setCheckChanges] = useState(false);
  const [easyMapsConfig, setEasyMapsConfig] = useState(null);
  useEffect(() => {
    let config = JSON.parse(localStorage.getItem("easyMapsConfig")) || null;
    if (config) setEasyMapsConfig(config);
  }, []);

  useEffect(() => {
    if (!easyMapsConfig) return;
    localStorage.setItem("easyMapsConfig", JSON.stringify(easyMapsConfig));
  }, [easyMapsConfig]);

  const { groupMapsId, id } = useParams();
  const styles = {
    fullScreem: {
      position: "fixed",
      zIndex: 3000,
      width: "100vw",
      height: "100vh",
      left: 0,
      top: 0,
    },
    loadingMap: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.mode != "dark" ? "#e0e0e093" : "#00000093",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: 4,
    },
  };

  useEffect(() => {
    if (elementSelected == null || editMode == true) return;

    setElementSelected({
      ...elements.find((ele) => ele.id == elementSelected.id),
    });
  }, [elements]);
  useEffect(() => {
    if (connectionSelected == null || checkChanges) return;

    setConnectionSelected({
      ...connections.find((conn) => conn.id == connectionSelected.id),
    });
  }, [connections]);

  useEffect(() => {
    setLoadingMap(true);
    inicializeMap();
    getIconDB();
    handleApiGetProdutos(tipo);
    handleApiGetMaps();

    const interval = window.setInterval(() => {
      updateDataElementSocket();
    }, 60000);

    return () => window.clearInterval(interval);
  }, [id]);
  async function inicializeMap() {
    await Promise.all([handleApiGetMap(id), handleApiGetElements(id)]);
    setLoadingMap(false);
  }
  async function handleApiGetMap(id) {
    try {
      const response = await api.post("/easyMaps/map/findMapById", {
        mapId: id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar o mapa",
          severity: "error",
        });
      } else {
        if (response.data.tipo != 0) navigate("/easyMaps");
        else setMap(response.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status == 404) navigate("/easyMaps/notFound");
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function updateDataElementSocket() {
    try {
      socketCliente.emit("getDataElementsMap", { mapId: id }, (response) => {
        if (response.status !== 200) {
          setSnackbar({
            children: "Error: Não foi possível atualizar os elemntos",
            severity: "error",
          });
        } else {
          let responseData = response?.data;

          setElements((elements) => {
            elements.map((ele) => {
              let elemntoUpdate = responseData?.find(
                (eleUpdate) => eleUpdate?.elementId == ele?.id
              );

              //atualiza os alertas
              ele.alerts = elemntoUpdate?.alerts;
              //adicionar ping, snmp e latẽncia em data
              if (!ele?.data) ele["data"] = {};

              let itens = elemntoUpdate?.itensHosts || [];

              ele.data["itens"] = itens?.filter(
                (con) =>
                  con.key_?.toLowerCase() != "icmppingloss" &&
                  con.key_?.toLowerCase() != "snmp" &&
                  con.key_?.toLowerCase() != "icmppingsec" &&
                  con.key_?.toLowerCase() != "icmpping"
              );

              ele.data["perdaPing"] = itens?.filter(
                (con) => con.key_?.toLowerCase() == "icmppingloss"
              );

              ele.data["snmp"] = itens?.filter(
                (con) => con.key_?.toLowerCase() == "snmp"
              );

              ele.data["latencia"] = itens?.filter(
                (con) => con.key_?.toLowerCase() == "icmppingsec"
              );
              ele.data["ping"] = itens?.filter(
                (con) => con.key_?.toLowerCase() == "icmpping"
              );
            });
            return [...elements];
          });

          let connectionsUpdate = responseData.flatMap(
            (ele) => ele.connections
          );

          setConnections((connections) => {
            return [
              ...connections.map((conn) => {
                let connectionUpdate = connectionsUpdate.find(
                  (connUp) => connUp.id == conn.id
                );
                if (!connectionUpdate) return conn;

                //adiciona as infomações da conexão em data
                if (!conn.data) conn["data"] = {};

                conn.data["nameTraffic"] = (
                  connectionUpdate?.itens?.find(
                    (con) =>
                      con.key_?.toLowerCase().includes("inoctets") ||
                      con.key_?.toLowerCase().includes("outoctets")
                  )?.name || null
                )

                  ?.replace(" de Entrada", "")
                  ?.replace(" de Saída", "");

                conn.data["inputTraffic"] =
                  connectionUpdate?.itens?.find((con) =>
                    con.key_?.toLowerCase().includes("inoctets")
                  )?.lastvalue || 0;

                conn.data["outputTraffic"] =
                  connectionUpdate?.itens?.find((con) =>
                    con.key_?.toLowerCase().includes("outoctets")
                  )?.lastvalue || 0;

                conn.data["units"] =
                  connectionUpdate?.itens?.find((con) =>
                    con.key_?.toLowerCase().includes("inoctets")
                  )?.units || null;

                conn.data["status"] = connectionUpdate?.status;
                conn.data["itens"] =
                  connectionUpdate?.itens
                    ?.filter(
                      (con) =>
                        !con.key_?.toLowerCase().includes("inoctets") &&
                        !con.key_?.toLowerCase().includes("outoctets")
                    )
                    ?.map((item) => ({
                      name: item.name,
                      lastValue: item.lastvalue,
                      units: item.units,
                    })) || [];

                return conn;
              }),
            ];
          });
        }
      });
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível atualizar",
        severity: "error",
      });
    }
  }

  async function handleApiGetMaps() {
    try {
      const response = await api.get("/easyMaps/map/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          setMapas(
            response.data.map((map) => ({
              nome: map.titulo,
              id: map.id,
              tipo: map.tipo,
            }))
          );
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiGetElements(id) {
    try {
      //setLoadingProdutos(true);
      const response = await api.post("/easyMaps/element/findMany", {
        mapId: id,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar o mapa",
          severity: "error",
        });
      } else {
        setElements(response.data.responseAlerts);
        setConnections(response.data.connections);
        updateDataElementSocket();
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiGetProdutos(tipo) {
    try {
      //setLoadingProdutos(true);
      const response = await api.post("/cliente/produto/findMany", {
        tipo,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setProdutos(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function getIconDB() {
    try {
      const response = await api.get("/easyMaps/img/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar os icons",
          severity: "error",
        });
      } else {
        setIconsElements(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  return (
    <>
      <Stack direction="row">
        <SliderBarMaps
          map={map}
          iconsElements={iconsElements}
          mapRef={mapRef}
          elements={elements}
        />
        <Stack
          sx={
            !fullScreem
              ? {
                  position: "relative",
                }
              : styles.fullScreem
          }
          width="100%"
        >
          {loadingMap && (
            <Box sx={styles.loadingMap}>
              <CircularProgress size="50px" disableShrink />
              <Typography
                fontSize="14px"
                color={theme.palette.color.textEasyMaps}
              >
                Carregando dados do mapa...
              </Typography>
            </Box>
          )}

          <LeafletMapa
            setEasyMapsConfig={setEasyMapsConfig}
            easyMapsConfig={easyMapsConfig}
            setFullScreem={setFullScreem}
            fullScreem={fullScreem}
            socketCliente={socketCliente}
            ref={mapRef}
            elements={elements}
            connections={JSON.parse(JSON.stringify(connections))}
            setElementSelected={setElementSelected}
            connectionSelected={connectionSelected}
            setConnectionSelected={setConnectionSelected}
            elementSelected={elementSelected}
            iconsElements={iconsElements}
            setIconsElement={setIconsElements}
            mapId={id}
            title={map?.titulo}
            element={editMode ? elementSelected : null}
            setElements={setElements}
            setConnections={setConnections}
            produtos={produtos}
            api={api}
            setSnackbar={setSnackbar}
            mapas={mapas}
            setMapas={setMapas}
            editMode={editMode}
            setEditMode={setEditMode}
            updateDataElementSocket={updateDataElementSocket}
            checkChanges={checkChanges}
            setCheckChanges={setCheckChanges}
          />
        </Stack>
      </Stack>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </>
  );
}
