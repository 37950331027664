import {
  ArrowRight,
  CancelOutlined,
  Delete,
  DeleteForever,
  ErrorOutline,
  LocalFireDepartmentOutlined,
  MoreVert,
  Share,
  TextFields,
  WarningAmberOutlined,
  Link,
  ContentCopyOutlined,
  SignalCellularConnectedNoInternet1Bar,
  SignalCellularNodataOutlined,
  SignalCellularConnectedNoInternet4BarOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { useState } from "react";
import api from "../../../services/api";
import AlertModal from "../../alertModal";
import ModalShareMap from "./modalShareMap";

function AlertIcons({ styles, number = 0, severity = "warning" }) {
  const theme = useTheme();
  const color =
    severity === "disaster"
      ? "color.zabbixSeverty.disaster"
      : "color.zabbixSeverty.average";

  return (
    <>
      <Box sx={[styles.iconsCircle, { backgroundColor: color }]}>
        <Typography sx={styles.subtitleAlert}>{number.toString()}</Typography>

        {severity === "disaster" ? (
          <SignalCellularNodataOutlined
            sx={{
              ...styles.icons,
            }}
          />
        ) : (
          <SignalCellularConnectedNoInternet4BarOutlined sx={styles.icons} />
        )}
      </Box>
    </>
  );
}
function SubMenu({
  anchorElSubmenu,
  openSubmenu,
  setAnchorElSubmenu,
  handleClose,
  setOpenModalShare,
  handleCopy,
  hasLink = false,
  theme = {},
}) {
  return (
    <Popper
      //open={true}
      id="basic-menu-submenu"
      sx={{ zIndex: 1300 }}
      anchorEl={anchorElSubmenu}
      open={openSubmenu}
      onClose={() => {
        setAnchorElSubmenu(null);
      }}
      placement="right-start"
    >
      <Paper
        elevation={4}
        sx={{ bgcolor: theme.palette.mode === "dark" ? "#222222" : "none" }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenModalShare(true);
            }}
          >
            <ListItemIcon>
              <Share fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Compartilhar</ListItemText>{" "}
          </MenuItem>
        </MenuList>

        <MenuList>
          <MenuItem
            disabled={!hasLink}
            onClick={() => {
              handleClose();
              handleCopy();
            }}
          >
            <ListItemIcon>
              <Link fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Copiar Link</ListItemText>{" "}
          </MenuItem>
        </MenuList>
      </Paper>
    </Popper>
  );
}
export default function CardMapsHomeRp({
  id = "",
  mapName = "Desconhecido",
  tipo = 0,
  hostsNames = [],
  hostsCounts = 0,
  popsCounts = 0,
  mapasCounts = 0,
  editMode = false,
  handleDelete,
  index = "",
  inGroup = true,
  updateTitleInMaps = () => {},
  setSnackbar = () => {},
  deleteMapsInMaps = () => {},
  duplicateMapsInMaps = () => {},
  updateMapTokenInMaps = () => {},
  alertas = [],
  mapas = [],
  pops = [],
  mapToken,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSubmenu, setAnchorElSubmenu] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAlertsModal, setOpenAlertsModal] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newTitle, setNewTitle] = useState(mapName);
  const [loadinButtom, setLoadingButtom] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [openModalShare, setOpenModalShare] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const styles = useStyles(theme, editMode);

  const open = Boolean(anchorEl);
  const openSubmenu = Boolean(anchorElSubmenu);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopy = () => {
    const url = `${window.location.href}/public/rp/${id}/${mapToken.token}`;
    navigator.clipboard.writeText(url);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  const handleMouseDown = (event) => {
    const url = `${window.location.origin}/easyMaps/rp/${id}`;
    if (event.button === 1) {
      window.open(url, "_blank");
    }
  };
  return (
    <Card sx={{ ...styles.card, flexGrow: 1 }} variant="outlined" index={index}>
      {editMode && (
        <Tooltip title={"Clique para remover do grupo"} placement="top" arrow>
          <CardActionArea
            sx={styles.deleteCardAction}
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(id);
            }}
          >
            <DeleteForever sx={styles.deleteIcon} color={"primary"} />
          </CardActionArea>
        </Tooltip>
      )}
      <CardActionArea
        sx={styles.cardAction}
        onClick={() => {
          navigate(
            `/easyMaps/${tipo === 0 ? "geo" : tipo === 3 ? "rp" : "top"}/${id}`
          );
        }}
        onMouseDown={handleMouseDown}
      >
        <Box sx={styles.cardType}>
          {tipo === 0 && <Typography sx={styles.typeText}>{"GEO"}</Typography>}
          {tipo === 1 && <Typography sx={styles.typeText}>{"TOP"}</Typography>}
          {tipo === 2 && <Typography sx={styles.typeText}>{"POP"}</Typography>}
          {tipo === 3 && <Typography sx={styles.typeText}>{"FTTH"}</Typography>}
        </Box>
        {!inGroup && (
          <IconButton
            id="options-button"
            sx={styles.options}
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
        )}

        <Stack gap={"5px"} mt={2}>
          <Typography sx={styles.title}>{mapName}</Typography>
          <Stack gap={"10px"} direction={"row"}>
            <Typography sx={styles.subtitle}>
              {mapasCounts + " CTOs"}
            </Typography>
            <Typography sx={styles.subtitle}>
              {hostsCounts + " ONUs"}
            </Typography>
          </Stack>
        </Stack>
        <Divider width={"100%"} orientation="horizontal" />
        {/*         <Box>
          <Typography sx={styles.hostsNames}>
            {hostsNames.map((name, index) => {
              const newName = name + (index === 0 ? " " : " ,");
              return newName;
            })}
          </Typography>
        </Box> */}
        <Stack direction="row" sx={styles.alerts}>
          {/* <Typography sx={styles.title}>{"Alertas"}</Typography>{" "} */}
          <Typography variant="textFieldTitle" pr={1}>
            {"Alertas:  "}
          </Typography>

          <Stack direction="row" sx={styles.alertsIcons}>
            <AlertIcons
              styles={styles}
              number={`${
                alertas.find((alert) => alert.severity === "1")?.value ?? 0
              }`}
              severity={"disaster"}
            />
            <AlertIcons
              styles={styles}
              number={`${
                alertas.find((alert) => alert.severity === "0")?.value ?? 0
              }`}
              severity={"warning"}
            />
          </Stack>
        </Stack>
      </CardActionArea>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "options-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              // handleClose();
              //setOpenModalShare(true);
            }}
            onMouseEnter={(event) => {
              setAnchorElSubmenu(event.currentTarget);
            }}
            onMouseLeave={(event) => {
              setAnchorElSubmenu(null);
            }}
          >
            <ListItemIcon>
              <Share fontSize="small" color="primary" />
            </ListItemIcon>
            <Stack
              direction={"row"}
              width={"150px"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <ListItemText>Compartilhar</ListItemText>{" "}
              <ArrowRight fontSize="small" color="action" />
            </Stack>
            <SubMenu
              anchorElSubmenu={anchorElSubmenu}
              openSubmenu={openSubmenu}
              setAnchorElSubmenu={setAnchorElSubmenu}
              handleClose={handleClose}
              setOpenModalShare={setOpenModalShare}
              handleCopy={handleCopy}
              hasLink={!!mapToken?.token && mapToken.status}
              theme={theme}
            />
          </MenuItem>
        </MenuList>
      </Menu>

      <ModalShareMap
        id={id}
        tipo={tipo}
        mapName={mapName}
        openModalShare={openModalShare}
        setOpenModalShare={setOpenModalShare}
        mapas={[...mapas, ...pops.filter(({ mapRefId }) => !!mapRefId)]}
        setSnackbar={setSnackbar}
        updateMapTokenInMaps={updateMapTokenInMaps}
        index={index}
        mapToken={mapToken}
      />
      <AlertModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        confirmar={disableButton}
        //handleButton={handleApiDelete}
        severity={"error"}
        singleButton={false}
      />
    </Card>
  );
}
//export { CardMapsHome };
