import L from "leaflet";
import { Box, Stack, useTheme, Paper, Modal, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import IconTransform from "../../../../componentes/easyMaps/iconsElement";
import * as ReactDOMServer from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";
import styled, { css, keyframes } from "styled-components";

function map_range(value, low1, high1, low2, high2) {
  return parseInt(low2 + ((high2 - low2) * (value - low1)) / (high1 - low1));
}

const pulseRingAnimation = keyframes`
0% {
  transform: scale(0.33);
}
80%, 100% {
  opacity: 0;
}
`;

const alternateBorder = keyframes`
0% {
  stroke: #eb9e9e8f;
}
100% {
   stroke: #EE1127 ;
}
`;
const alternateBorderCircle = keyframes`
0% {
  border: 2px solid #eb9e9e8f;
}
100% {
  border: 2px solid #EE1127;
 
}
`;

const CustomTooltip = styled(Tooltip)`
  background-color: transparent !important; /* Fundo do tooltip */
  color: ${(props) => props.color};
  font-size: 14px; /* Tamanho da fonte */
  padding: 5px; /* Padding */
  border: none; /* Borda */
  box-shadow: none; /* Sombra */
  font-weight: 600;

  &::before {
    content: none; /* Remove o conteÃºdo do ::before, se houver */
  }
`;

const Pulse = styled.div`
  pointer-events: none;

  position: absolute;
  z-index: -1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  &:before {
    content: "";

    display: block;
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    margin-left: ${(props) =>
      props.variant === "customIcon"
        ? map_range(props.size, 8, 10, -40, -36) + "px"
        : "-50px"};
    margin-top: ${(props) =>
      props.variant === "customIcon" ? "-35px" : "-50px"};
    border-radius: 50%;
    background-color: ${(props) => props.color};
    animation: ${pulseRingAnimation} 2s cubic-bezier(0.215, 0.5, 0.355, 1)
      infinite;
  }
`;

const Select = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); // Aumenta o tamanho em 10%
  }
  100% {
    transform: scale(1); // Retorna ao tamanho original
  }
`;

const SelectSVG = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); // Aumenta o tamanho em 10%
  }
  100% {
    transform: scale(1); // Retorna ao tamanho original
  }
`;

const AnimatedStack = styled.div`
  position: absolute;
  bottom: -11px;
  width: 100%,
  height: 100%;
  width: 24px;
  border-radius: 50%;
  height: 24px;
  border: 2px solid ${({ border }) => border};
  box-shadow:  0px 0px 12px ${({ selected, border, isDown }) =>
    selected ? (isDown ? "#EE1127" : border) : "none"};
  animation: ${({ selected }) =>
    selected
      ? css`
          ${Select} 2s infinite
        `
      : "none"},
    ${({ isDown, editable }) =>
      isDown && !editable
        ? css`
            ${alternateBorderCircle} 0.5s infinite alternate
          `
        : "none"};
`;

const AnimatedSVG = styled.svg`
  width: 100%;
  stroke: ${({ border }) => border};
  filter: drop-shadow(
    0px 0px 12px
      ${({ selected, border, isDown }) =>
        selected ? (isDown ? "#EE1127" : border) : "none"}
  );
  height: 100%;
  animation: ${({ selected }) =>
      selected
        ? css`
            ${SelectSVG} 2s infinite
          `
        : "none"},
    ${({ isDown, editable }) =>
      isDown && !editable
        ? css`
            ${alternateBorder} 0.5s infinite alternate
          `
        : "none"};
`;

const NodeIcon = (
  {
    size = 10,
    showLabel,
    element,
    color,
    editable,
    onNodeClick = () => {},
    onNodeContextMenu = () => {},
    iconsElement = () => {},
    handleUpdatePositionElement = () => {},
    selected = false,
    easyMapsConfig,
  },
  key
) => {
  const theme = useTheme();
  const [severity, setSeverity] = useState("1");
  const [isDown, setIsdown] = useState(false);
  const [animationAlert, setAnimationAlert] = useState(true);

  const severityColors = {
    5: {
      background: theme.palette.color?.zabbixSeverty?.disaster,
      border: theme.palette.color?.zabbixSeverty?.disaster,
    },
    4: {
      background: theme.palette.color.zabbixSeverty.highTranslucid,
      border: theme.palette.color.zabbixSeverty.high,
    },
    3: {
      background: theme.palette.color.zabbixSeverty.averageTranslucid,
      border: theme.palette.color.zabbixSeverty.average,
    },
    2: {
      background: theme.palette.color.zabbixSeverty.warningTranslucid,
      border: theme.palette.color.zabbixSeverty.warning,
    },
    1: {
      background: theme.palette.color.zabbixSeverty.default,
      border: theme.palette.color.zabbixSeverty.selectedTranslucid,
    },
    0: {
      background: "#ffffff36",
      border: "#ffffff8f",
    },
  };

  const [colors, setColors] = useState({
    background: theme.palette.color?.zabbixSeverty?.warningTranslucid,
    border: theme.palette.color?.zabbixSeverty?.warning,
  });

  const styles = {
    alerts: {
      position: "absolute",
      background: colors.border,
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      display: element?.alerts?.length > 0 && !editable ? "flex" : "none",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 700,
      zIndex: 10,
      right: -size * 0.25 + "px",
      top: -10 + "px",
    },
    iconBox: {
      width: 0.5 * size * 10 + "px",
      height: 0.6 * size * 10 + "px",
      position: "relative",
    },
    icon: {
      position: "absolute",
      marginTop: "-" + map_range(size, 4, 11, 25, 65) + "px",
      marginLeft: 0.1 * size * 10 + "px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  useEffect(() => {
    if (!easyMapsConfig) return;
    easyMapsConfig?.animacao?.alerta == null ||
    easyMapsConfig?.animacao?.alerta == true
      ? setAnimationAlert(true)
      : setAnimationAlert(false);
  }, [easyMapsConfig]);

  useEffect(() => {
    if (element.tipo > 0 || !element.data) return;
    let ping = element.data?.ping[0]?.lastvalue;
    let snmp = element.data?.snmp[0]?.snmp_available;
    setIsdown(!(ping == true || snmp == true));
  }, [element.data]);

  useEffect(() => {
    if (editable || isDown) {
      setSeverity(1);
      return;
    }
    let sev = 1;
    element?.alerts?.map((alert) => {
      if (alert.severity > sev) sev = alert.severity;
    });
    setSeverity(sev);
  }, [element.alerts, editable]);

  useEffect(() => {
    setColors(severityColors[severity] || severityColors["1"]);
  }, [severity, theme]);

  const CustomIconCircle = () => (
    <>
      <AnimatedStack
        justifyContent="center"
        alignItems="center"
        selected={selected}
        position={"relative"}
        border={colors.border}
        isDown={isDown}
        editable={editable}
      >
        {severity > 1 && !isDown && !editable && animationAlert && (
          <Pulse color={colors.border} />
        )}

        <Box
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor:
              severity > 4
                ? colors.background
                : theme.palette.background.default,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconTransform
            img={
              iconsElement?.find((icon) => icon.id == element.img)?.img ||
              iconsElement?.find(
                (icon) =>
                  icon.nome ==
                  (element.tipo === 0
                    ? "_roteador"
                    : element.tipo === 1
                    ? "_mapa"
                    : "_pop")
              )?.img
            }
            color={severity > 4 ? "white" : color}
            //size={size * 3 < 14 ? 14 : size * 3}
            size={18}
          />
        </Box>
      </AnimatedStack>
    </>
  );

  const CustomIconC = () => (
    <>
      <Box
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          bottom: "-6px",
        }}
      >
        <Box
          style={{
            width: "13px",
            height: "13px",
            borderRadius: "50%",
            backgroundColor: colors.border,
            border: "2px solid" + colors.border,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></Box>
      </Box>
    </>
  );

  const CustomIcon = () => (
    <>
      {severity > 1 && !editable && !isDown && animationAlert && (
        <Pulse color={colors.border} size={size} variant="customIcon" />
      )}
      <Box
        style={{
          ...styles.iconBox,
        }}
      >
        <AnimatedSVG
          viewBox="0 0 61 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          selected={selected}
          border={colors.border || "#794EA4"}
          isDown={isDown}
          editable={editable}
        >
          <g clipPath="url(#clip0_3415_75702)">
            <path
              d="M60.5 32.3279C60.5 40.5441 55.6822 49.0818 49.3749 57.0649C44.3952 63.3676 38.5424 69.259 33.5186 74.316C32.3224 75.5201 31.1732 76.6769 30.0939 77.7807C29.1845 76.8332 28.2168 75.8377 27.2072 74.7991C22.32 69.7717 16.4508 63.734 11.4501 57.2306C5.27016 49.1939 0.5 40.5477 0.5 32.3279C0.5 15.7661 13.5677 0.5 30.0906 0.5C46.6302 0.5 60.5 15.7842 60.5 32.3279Z"
              fill={colors.background}
              strokeWidth={selected || isDown ? "3px" : "2px"}
              filter="url(#filter0_d)"
            />
          </g>
        </AnimatedSVG>

        <Box style={styles.icon}>
          <IconTransform
            img={
              iconsElement?.find((icon) => icon.id == element.img)?.img ||
              iconsElement?.find(
                (icon) =>
                  icon.nome ==
                  (element.tipo === 0
                    ? "_roteador"
                    : element.tipo === 1
                    ? "_mapa"
                    : "_pop")
              )?.img
            }
            color={severity > 4 ? "white" : color}
            size={size * 3}
          />
        </Box>
      </Box>
    </>
  );

  const iconPerson = () => {
    return L.divIcon({
      html: ReactDOMServer.renderToString(
        size > 7 ? <CustomIcon /> : <CustomIconCircle /> //<CustomIconC />
      ),
      // iconSize: [0.5 * size * 10, 0.6 * size * 10],
      iconSize: [0.5 * size * 10, 0.6 * size * 10],
      iconAnchor: [0.25 * size * 10, 0.6 * size * 10],
    });
  };

  return (
    <Marker
      key={key}
      draggable={editable && selected}
      eventHandlers={{
        dragend: (e) => {
          handleUpdatePositionElement(element, e.target._latlng);
        },
        click: (e) => {
          onNodeClick(e, element);
        },
        contextmenu: (e) => {
          onNodeContextMenu(e, element);
        },
      }}
      position={[
        element?.elementConfig?.config?.coordenadas?.lat || 0,
        element?.elementConfig?.config?.coordenadas?.long || 0,
      ]}
      icon={iconPerson()}
    >
      <CustomTooltip
        key={size + color}
        direction="top"
        offset={[
          -1,
          size <= 7 ? 0 : -(24 + size * 3.2 + (severity <= 1 ? -8 : 0)),
        ]}
        permanent
        theme={theme}
        color={color}
      >
        {showLabel ? element?.titulo : ""}
      </CustomTooltip>
    </Marker>
  );
};

export default NodeIcon;
