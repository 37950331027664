import {
  Divider,
  Box,
  Paper,
  Stack,
  Typography,
  useTheme,
  LinearProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import StatusCard from "../statusCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StatusONU from "./status";
import { colorPower } from "../../descriptionBarRp";
export default function CardONU(
  {
    onu,
    index,
    expanded = false,
    setExpanded,
    unique = false,
    setFocusNodeId = () => {},
    setElementONUSelected = () => {},
    setEnderecosOpen = () => {},
    setMoreOpen = () => {},
    enderecosOpen,
    moreOpen,
  },
  key
) {
  const theme = useTheme();
  const [colorAlert, setColorAlert] = useState("");
  const [pEntrada, setPEntrada] = useState("-");
  const [pSaida, setPSaida] = useState("-");
  const styles = {
    line: {
      position: "absolute",
      height: "80%", //typeElement == "Host" ? "150px" : "230px",
      minWidth: "3px",
      ml: "-5px",
    },

    textMedium: {
      color: theme.palette.color.textDisabled,
      fontSize: "0.85rem",
    },
    textLarge: { fontSize: "2em" },
    contentTab: { gap: "10px" },
    detailsCard: {
      backgroundColor: theme.palette.background.paperLinkedMaps,
      paddingX: "10px",
      paddingY: "15px",
      display: "flex",
      flexDirection: "column",
      gap: 2,
    },
    text: {
      fontSize: "0.85em",
      width: "200px",
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limite de 2 linhas
      WebkitBoxOrient: "vertical",
      textAlign: "left",
    },
    textAlerta: {
      fontSize: "0.85rem",

      color: theme.palette.color.textDisabled,
    },
    textHeaderAco: {
      fontSize: "0.85rem",

      color: theme.palette.color.text,
    },
  };

  function handleChange(selected) {
    setExpanded(selected == expanded ? null : index);
    if (selected == expanded || selected == null) {
      setElementONUSelected(null);
    } else if (selected == index) {
      setElementONUSelected(onu);
      setFocusNodeId(onu.id);
    }
  }
  useEffect(() => {
    let status = onu?.data?.status.value;
    if (status) setColorAlert(colorPower(onu?.data?.potencia?.rx, theme));
    else setColorAlert(colorPower(-40, theme));
    if (onu?.data?.potencia?.rx != -40 && status)
      setPEntrada(onu?.data?.potencia?.rx);
    else setPEntrada("-40");
    if (onu?.data?.potencia?.tx != -40 && status)
      setPSaida(onu?.data?.potencia?.tx);
    else setPSaida("-40");
  }, [onu]);

  function formatString(input) {
    return input
      .replace(/([A-Z])/g, " $1") // Adiciona um espaço antes de letras maiúsculas
      .replace(/^./, (str) => str.toUpperCase()) // Coloca a primeira letra em maiúscula
      .replace(/([a-z])([A-Z])/g, "$1 $2"); // Adiciona espaço entre letras minúsculas e maiúsculas
  }

  return (
    <Accordion
      key={key}
      disableGutters
      expanded={expanded == index || unique}
      onChange={() => handleChange(index)}
      sx={{
        mr: "10px",
        padding: "5px",
        borderRadius: "5px",
        backgroundColor: theme.palette.background.paperSelectedMaps,
        boxShadow: "0px 0px 3px  #0000001a",

        border: "none",
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={!unique ? <ExpandMoreIcon /> : null}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          padding: "0px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          ml={1}
        >
          {/* {expanded != index && !unique && ( */}
          <Box sx={[styles.line, { backgroundColor: colorAlert }]} />
          {/* )} */}

          <Stack direction={"column"} gap={1} ml={1.5}>
            <Tooltip title={onu.titulo}>
              <Typography sx={styles.text}>{onu.titulo}</Typography>
            </Tooltip>
          </Stack>
        </Stack>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          padding: "5px",
          display: "flex",
          gap: 1,
          flexDirection: "column",
        }}
      >
        <Paper sx={styles.detailsCard} elevation={0}>
          <Stack direction={"column"} gap={2}>
            <Typography textAlign={"center"} sx={styles.textAlerta}>
              Potência da ONU
            </Typography>
            <Stack direction={"row"} justifyContent={"space-around"}>
              <Stack alignItems={"center"}>
                <Stack direction={"row"} sx={{ alignItems: "flex-end" }}>
                  <Typography sx={styles.textLarge}>{pEntrada}</Typography>
                  <Typography color="primary" mb={"6px"}>
                    dBm
                  </Typography>
                </Stack>
                <Typography sx={styles.textMedium}>Entrada</Typography>
              </Stack>
              <Stack alignItems={"center"}>
                <Stack direction={"row"} sx={{ alignItems: "flex-end" }}>
                  <Typography sx={styles.textLarge}> {pSaida}</Typography>
                  <Typography color="primary" mb={"6px"}>
                    dBm
                  </Typography>
                </Stack>{" "}
                <Typography sx={styles.textMedium}> Saida</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Divider variant="middle" />
          <StatusONU potenciaRx={pEntrada} />
        </Paper>
        <Accordion
          key={onu.id + "localizacao"}
          disableGutters
          sx={{
            backgroundColor: theme.palette.background.paperLinkedMaps,
            boxShadow: "none",
            "&::before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh"
          >
            <Typography sx={styles.textHeaderAco}>Endereço</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {Object.keys(onu?.data.localizacao).map((nick, index) => (
              <Fragment key={"dadosCliente" + index}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                  width="100%"
                >
                  <Typography
                    sx={styles.textAlerta}
                    fontSize={"0.7rem"}
                    width="170px"
                  >
                    {formatString(nick)}
                  </Typography>

                  <Typography
                    textAlign={"end"}
                    fontSize={"0.85rem"}
                    width={"200px"}
                  >
                    {onu?.data?.localizacao?.[nick]}
                  </Typography>
                </Stack>
                <Divider sx={{ mt: 1, mb: 1 }} />
              </Fragment>
            ))}{" "}
          </AccordionDetails>
        </Accordion>
        <Accordion
          key={key + "outrosItens"}
          disableGutters
          sx={{
            backgroundColor: theme.palette.background.paperLinkedMaps,
            boxShadow: "none",
            "&::before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh"
          >
            <Typography sx={styles.textHeaderAco}>Mais detalhes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {Object.keys(onu?.data)
              .filter(
                (a) => a != "localizacao" && a != "potencia" && a != "status"
              )
              .map((nick, index) => (
                <Fragment key={"dadosCliente" + index}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={"space-between"}
                    width="100%"
                  >
                    <Typography
                      sx={styles.textAlerta}
                      fontSize={"0.7rem"}
                      width="170px"
                    >
                      {formatString(nick)}
                    </Typography>

                    <Stack direction={"row"} gap={"2px"}>
                      {onu?.data?.[nick]?.unit != null ? (
                        <>
                          <Typography textAlign={"end"} fontSize="0.9rem">
                            {onu?.data?.[nick].value}
                          </Typography>
                          <Typography
                            color="primary"
                            variant="small"
                            fontSize="0.85rem"
                          >
                            {onu?.data?.[nick]?.unit}
                          </Typography>
                        </>
                      ) : Boolean(onu?.data?.[nick]?.value) ? (
                        <Typography
                          textAlign={"end"}
                          sx={{
                            color: theme.palette.color.status.up,
                            fontSize: "0.9rem",
                          }}
                        >
                          Ativo
                        </Typography>
                      ) : (
                        <Typography
                          textAlign={"end"}
                          sx={{
                            color: theme.palette.color.status.down,
                            fontSize: "0.9em",
                          }}
                        >
                          Inativo
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </Fragment>
              ))}{" "}
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
}
